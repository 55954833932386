// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_4972 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 550px;

  &:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    border: 10px solid #fff;
    left: 0;
    top: 0;


    @media only screen and (max-width: 990px) {
      display: none;
    }
  }

  .backstretch {
    position: fixed !important;
    height: 100vh !important;
  }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 450px;
  z-index: 1;

  &:before {
    display: none;
  }
}

.backstretch {
  position: fixed !important;
  height: 100vh !important;

  // for top of page gradient
  &:before {
    @include before(100%, 220px);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0) 80%);
    top: 0;
    left: 0;
  }

  // for btm of page gradient
  &:after {
    @include before(100%, 540px);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 90%);
    bottom: 0;
    left: 0;
  }

  img {
    top: 0px !important;
    min-width: 100% !important;
    min-height: 100% !important;
    object-fit: cover;
  }
}



.video-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
  position: fixed !important;
  height: 100vh !important;

  // for top of page gradient
  &:before {
    @include before(100%, 220px);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0) 80%);
    top: 0;
    left: 0;
    z-index: 10;
  }

  // for btm of page gradient
  &:after {
    @include before(100%, 540px);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 90%);
    bottom: 0;
    left: 0;
    z-index: 10;
  }
}

.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 750px;
  pointer-events: none;
  overflow: hidden;
}

.video-background iframe {
  height: 130%;
  top: -15%;
  left: 0;
  right: 0;
  margin: 0 auto;
  object-fit: fill;
}

// @media only screen and (max-width: 700px) {
//   .video-background {
//     display: none;
//   }
// }

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: 50px;
}