// <section id="social">

// <!-- Iframe container -->
// <!-- <iframe src="/inc/social/index.asp"></iframe> -->

// <!-- Close button -->
// <div id="close-social"><img src="/i/design/close.png" width="42" height="42" alt="Close"><br />Close</div>

// <!-- Sidebar -->
// <div id="sidebar">
// 	<h2>Social Media Posts</h2>
// </div>

// </section>

// // ------------------------------ 	Social Wall

// var isSliderOpen = false;
// var socialWidth = $('section#social').width();
// possible fix for sticky-out bit
// // var socialWidth = window.innerWidth;

// $('section#social').css({
// 	'right': '-' + socialWidth + 'px',
// 	'visibility': 'visible'
// });

// $('section#social div#sidebar').click(function () {
// 	if (isSliderOpen === false) {
// 		$('section#social').animate({
// 			right: 0
// 		}, 1000);
// 		$('div#sidebar').addClass('open');
// 		$('section#social').css({
// 			'overflow': 'visible',
// 			'-webkit-overflow-scrolling': 'touch'
// 		});
// 		isSliderOpen = true;
// 	} else {
// 		$('section#social').animate({
// 			right: '-' + socialWidth + 'px'
// 		}, 1000);
// 		$('section#social div#sidebar').removeClass('open');
// 		$('section#social').css({
// 			'overflow': 'visible'
// 		});
// 		isSliderOpen = false;
// 	}
// });
// $('#close-social').click(function () {
// 	$('section#social').animate({
// 		right: '-' + socialWidth + 'px'
// 	}, 1000);
// 	$('section#social div#sidebar').removeClass('open');
// 	$('section#social').css({
// 		'overflow': 'visible'
// 	});
// 	isSliderOpen = false;
// });

section#social {
	width: 100%;
	height: 100% !important;
	position: fixed;
	top: 0;
	left: auto;
	visibility: hidden;
	z-index: 99999;
	background: rgba(0, 0, 0, .8);

	// @media only screen and (max-width: 1170px) {
	// 	display: none;
	// }

	iframe {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	#sidebar {
		position: absolute;
		transition: all 0.2s ease-out;
		cursor: pointer;
		z-index: 99999;
		height: 142px;
		width: 126px;
		left: -153px;
		top: 66%;
		background: url(/i/design/social-wall.png) no-repeat;

		@media only screen and (max-width: 1170px) {
			background-size: 90%;
			scale: 0.8;
			left: -110px;
		}

		h2 {
			@include center;
			color: #fff;
			white-space: nowrap;
			transform: rotate(90deg);
			font-size: 26px;

			left: -75px;
		}
	}
}

#close-social {
	position: absolute;
	top: 15px;
	right: 30px;
	font-size: 13px;
	font-weight: 400;
	text-align: center;
	color: #fff;
	cursor: pointer;
	text-transform: uppercase;
}

#close-social img {
	padding: 14px 13px 13px 13px;
	border-radius: 50%;
	margin-bottom: 6px;
}

#close-social:hover {
	opacity: .7;
}

#close-social:hover img {
	opacity: .7;
}

.backstretch {
	height: 100%;
}

@media only screen and (max-width: 1170px) {

	section#social #sidebar {}

	section#social #sidebar h2 {}
}